import axios from 'axios';
import { axios as axiosInstance } from 'src/config/axios';
import storage from 'src/common/Storage';
import Toast from 'src/common/Toast';
import config from 'src/config/config';
import { store } from 'src';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const requestLogin = (credentials) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    credentials,
  };
};

export const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user,
  };
};

export const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch(requestLogout());
    await axiosInstance
      .post(`/logout`, {})
      .then((response) => {
        storage.remove('user');
        dispatch(receiveLogout());
      })
      .catch((error) => {
        // Error
        Toast.error(error.response.data.message);
        dispatch(loginError(error.response.data.message));
      });
  };
};

export const autoLogoutUser = async () => {
  localStorage.removeItem('user');
  store.dispatch(receiveLogout());

  await axios
    .post(`${config.baseUrl}/logout`, {})
    .then((response) => {
      // storage.set('user', response.data);
    })
    .catch((error) => {
      // Toast.error(error?.response?.data?.message);
    });
};

export const loadUserFromLocalStorage = () => {
  return (dispatch) => {
    const user = storage.get('user');
    if (user) {
      dispatch(receiveLogin({ user }));
    }
  };
};

export const loginUser = (creds) => {
  return async (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    await axios
      .post(`${config.baseUrl}/login`, creds)
      .then((response) => {
        dispatch(receiveLogin({ user: response.data }));
        storage.set('user', response.data);
      })
      .catch((error) => {
        Toast.error(error?.response?.data?.message);
        dispatch(loginError(error.response.data.message));
      });
  };
};
