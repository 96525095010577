import { Form, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import Table from 'src/components/Table';
import { filterFields, tableColumnsTimes } from '../maintenances-fields';
import FormFactory from 'src/components/FormFactory';
import MainButton from 'src/components/buttons/MainButton';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import TagsService from 'src/common/api/TagsService';
import { useParams } from 'react-router';
import UsersService from 'src/common/api/UsersService';

const MaintenanceAllTasks = ({ allTasks, getAllTasks, getMaintenanceData }) => {
  const [searchForm] = Form.useForm();
  const { maintenanceId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFormFields, setSearchFormFields] = useState([]);

  const getFiltersDropdownData = async () => {
    let tagsResponse = await TagsService.allWithoutPagination();
    let usersReponse = await UsersService.allWithoutPagination();

    setSearchFormFields(
      filterFields({
        tags: tagsResponse?.data,
        users: usersReponse?.data,
      })
    );
  };

  useEffect(() => {
    getFiltersDropdownData();
  }, [maintenanceId]);

  useEffect(() => {
    getAllTasks(1, searchQuery);
  }, [maintenanceId, searchQuery]);

  const updateTimeRecordStatus = async ({ timeRecordId, paymentStatus }) => {
    await MaintenancesService.updateMaintenancePaymentStatus(
      maintenanceId,
      timeRecordId,
      { payment_status: paymentStatus }
    );
    getMaintenanceData();
  };

  const onExportTimeRecords = async () => {
    let response = await MaintenancesService.exportMaintenanceTimeRecord(
      maintenanceId,
      searchQuery
    );
    window.open(response?.data, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='mt-5'>
      <FormFactory
        form={searchForm}
        onFinish={(data) => {
          setSearchQuery(Utils.createQueryString(data));
        }}
        fields={[
          ...searchFormFields,
          {
            colSize:
              'col-span-2 xl:col-start-11 sm:col-start-10 sm:col-end-11 flex justify-end',
            controlType: (
              <Tooltip title='Reset'>
                <MainButton
                  type='reset'
                  className='mt-7 ml-auto mr-0 mx-auto w-full'
                  icon={ICONS.reset}
                  onClick={() => {
                    setSearchQuery('');
                    let data = searchForm.getFieldsValue();
                    let setAll = (obj, val) =>
                      Object.keys(obj).forEach((k) => (obj[k] = val));
                    setAll(data, undefined);
                    searchForm.setFieldsValue(data);
                  }}
                />
              </Tooltip>
            ),
          },
          {
            colSize:
              'col-span-10 xl:col-start-12 sm:col-start-11 sm:col-end-13',
            controlType: (
              <MainButton
                label='Search'
                type='submit'
                className='mt-7 ml-auto mr-0 mx-auto w-full'
                icon={ICONS.search}
              />
            ),
          },
        ]}
      />
      <div className='flex flex-row justify-between mb-2 items-center'>
        <h3 className='font-normal'>All tasks</h3>
        <div className='flex flex-row items-center gap-4'>
          <h3 className='font-normal'>
            TOTAL: {Utils.calculateTrackedTime(allTasks?.data?.total_time ?? 0)}
          </h3>
          <MainButton
            label='Export'
            type='button'
            className='w-fit'
            onClick={onExportTimeRecords}
            icon={ICONS.export}
          />
        </div>
      </div>
      <Table
        data={{
          data: allTasks?.data?.time_records,
          pagination: allTasks?.pagination,
        }}
        columns={tableColumnsTimes(updateTimeRecordStatus)}
        onFilter={(page) => getAllTasks(page)}
        hideActions={true}
      />
    </div>
  );
};

export default MaintenanceAllTasks;
