import { Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ClientsService from 'src/common/api/ClientsService';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './clients-fields';
import MaintenancesList from './maintenances/MaintenancesList';

const ClientsEdit = () => {
  let { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    let { data } = await ClientsService.one(id);
    setData(data);
    form.setFieldsValue(data);
  };

  const onSubmit = (data) => {
    ClientsService.update(id, data);
  };

  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 sm:col-span-7 md:col-span-8'>
        <FormFactory
          form={form}
          onFinish={(data) => {
            onSubmit(data);
          }}
          fields={mainFields({ headerTitle: 'Client - Edit' })}
        />
      </div>

      {data?.active_maintenance && (
        <section className='col-span-12 sm:col-span-5 md:col-span-4 flex items-end'>
          <div className='bg-green-50 p-5 rounded-md border-green-300 border-solid border w-full'>
            <h4 className='m-0 mb-2 text-green-500 font-medium uppercase'>
              Active maintenance
            </h4>
            <section className='py-2 flex justify-between flex-wrap'>
              <div className='flex mb-2'>
                <div className='mr-5'>
                  <h3 className='m-0'>
                    {moment(data.active_maintenance.lease_start).format(
                      'DD.MM.YYYY'
                    )}
                  </h3>
                  <span className='text-slate-400'>Lease start</span>
                </div>
                <div className='mr-5'>
                  <h3 className='m-0'>
                    {moment(data.active_maintenance.lease_end).format(
                      'DD.MM.YYYY'
                    )}
                  </h3>
                  <span className='text-slate-400'>Lease end</span>
                </div>
              </div>
              <div className='flex'>
                <div className='mr-5'>
                  <h3 className='m-0'>
                    {data.active_maintenance.maintenance_hours}
                  </h3>
                  <span className='text-slate-400'>Hours</span>
                </div>
                <div className='mr-5'>
                  <h3 className='m-0'>
                    {data.active_maintenance.maintenance_hours_used}
                  </h3>
                  <span className='text-slate-400'>Hours used</span>
                </div>
                <div className='mr-5'>
                  <h3 className='m-0 text-red-500'>
                    {data.active_maintenance.maintenance_hours_left}
                  </h3>
                  <span className='text-slate-400'>Hours left</span>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}

      <div className='col-span-12'>
        <MaintenancesList clientId={id} />
      </div>
    </div>
  );
};

export default ClientsEdit;
