import { Select, Tag } from 'antd';
import { store } from 'src';
import MainButton from 'src/components/buttons/MainButton';
import config from 'src/config/config';
import { ICONS } from './Icons';
import { KpiTypes } from './enums/kpi';

const { Option } = Select;

const Utils = {
  generateFormInitialValues: (fields) => {
    const initialValues = {};

    fields.forEach((field) => {
      if (field?.options?.name && field?.options?.value) {
        initialValues[field?.options?.name] = field?.options?.value;
      } else if (field?.group) {
        field?.group.forEach((subfield) => {
          if (subfield?.options?.name && subfield?.options?.value)
            initialValues[subfield?.options?.name] = subfield?.options?.value;
        });
      }
    });

    return initialValues;
  },
  addPrefixToFormFields: (fields, prefix) => {
    const newFields = fields.map((field) => {
      if (field?.options?.name) {
        return {
          ...field,
          options: {
            ...field.options,
            name: `${prefix}${field.options.name}`,
          },
        };
      } else {
        return { ...field };
      }
    });

    return newFields;
  },
  observeStore: (onChange) => {
    let currentState;

    function handleChange() {
      let nextState = store.getState();
      if (nextState !== currentState) {
        currentState = nextState;
        onChange(currentState);
      }
    }

    let unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
  },
  logStore: () => {
    console.log(
      '%cCurrent store',
      'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;',
      store.getState()
    );
  },
  JSON: (json) => {
    if (config.production) return null;
    return (
      <pre className='p-3 bg-gray-800 shadow-2xl rounded-md overflow-hidden text-yellow-300'>
        {JSON.stringify(json, null, 2)}
      </pre>
    );
  },
  createDropdown: (data) => {
    return (
      <Select>
        {data.map((option) => {
          return (
            <Option
              key={option.id}
              value={option.id}
            >
              {option.name}
            </Option>
          );
        })}
      </Select>
    );
  },
  getObjectKeyValue: (object) => {
    const keys = Object.keys(object).filter(
      (k) => typeof object[k] === 'number'
    );
    const values = keys.map((k) => object[k]);

    return [keys, values];
  },
  createQueryString(form) {
    return `&${new URLSearchParams(form)
      .toString()
      .replaceAll('null', '')
      .replaceAll('undefined', '')}`;
  },
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(divisorForMinutes / 60);

    let divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return {
      render: (
        <>
          {`0${obj.h}`.slice(-2)}:{`0${obj.m}`.slice(-2)}:
          {`0${obj.s}`.slice(-2)}
        </>
      ),
      obj,
    };
  },
  secondsToHoursAndMinutes(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(divisorForMinutes / 60);

    let obj = {
      h: hours,
      m: minutes,
    };
    return {
      render: (
        <>
          {`0${obj.h}`.slice(-2)}:{`0${obj.m}`.slice(-2)}
        </>
      ),
      obj,
    };
  },
  calculateTrackedTime(secs) {
    secs = Math.abs(secs);

    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const seconds = secs % 60;

    const hoursStr = String(hours);
    const minutesStr = `0${minutes}`.slice(-2);
    const secondsStr = `0${seconds}`.slice(-2);

    return `${hoursStr}:${minutesStr}`;
  },
  randomRGBA() {
    const o = Math.round,
      r = Math.random,
      s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',.2)';
  },
  createHeader({ title, hideSave }) {
    return [
      {
        colSize: 'col-span-6 ',
        controlType: <h2 className='font-normal'>{title}</h2>,
      },
      {
        colSize: 'col-span-6 flex items-center justify-end',
        controlType: (
          <MainButton
            hide={hideSave}
            label='Save'
            type='submit'
            className='mt-2 w-fit'
            icon={ICONS.save}
          />
        ),
      },
    ];
  },
  secondsToTimeRounded: (secs) => {
    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.ceil(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: secs % 60 > 0 ? minutes + 1 : minutes,
      s: seconds,
    };
    return {
      render: (
        <>
          {`0${obj.h}`.slice(-2)}:{`0${obj.m}`.slice(-2)}:00
        </>
      ),
      obj,
    };
  },
  generateYearObjects: (startYear) => {
    const nextYear = new Date().getFullYear() + 1;
    const yearsArray = [];

    for (let year = nextYear; year >= startYear; year--) {
      yearsArray.push({ year: year.toString() });
    }

    return yearsArray;
  },
  getCurrentQuarter: () => {
    const month = new Date().getMonth() + 1;
    return `Q${Math.ceil(month / 3)}`;
  },
  renderKpiType: (kpiType, kpiTypeName) => {
    switch (kpiType) {
      case KpiTypes.PERSONAL:
        return <Tag color='cyan'>{kpiTypeName}</Tag>;
      case KpiTypes.TEAM:
        return <Tag color='red'>{kpiTypeName}</Tag>;
      case KpiTypes.BONUS:
        return <Tag color='green'>{kpiTypeName}</Tag>;
      case KpiTypes.ORGANIZATIONAL:
        return <Tag color='volcano'>{kpiTypeName}</Tag>;
    }
  },
  getQuarterMonths: (quarter) => {
    const quarters = {
      Q1: ['January', 'February', 'March'],
      Q2: ['April', 'May', 'June'],
      Q3: ['July', 'August', 'September'],
      Q4: ['October', 'November', 'December'],
    };

    return quarters[quarter].map((month) => ({
      month: month,
      personal_grade: '',
      team_lead_grade: '',
    }));
  },
  formatDecimalToTime(decimal) {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}`;

    return formattedTime;
  },
  convertDateTime(datetime) {
    const date = new Date(datetime);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  },
};

export default Utils;
