import { Divider, Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import MaintenancesService from 'src/common/api/MaintenancesService';
import FormFactory from 'src/components/FormFactory';
import { editFields } from './maintenances-fields';
import Utils from 'src/common/Utils';
import MaintenancesTabs from './maintenancesTabs/MaintenancesTabs';

const MaintenancesEdit = () => {
  const [maintenanceData, setMaintenanceData] = useState({});
  let { maintenanceId } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    getData();
  }, [maintenanceId]);

  const getData = async () => {
    let { data } = await MaintenancesService.one(maintenanceId);
    setMaintenanceData(data);
    form.setFieldsValue({
      ...data,
      lease_start: moment(data.lease_start),
      lease_end: moment(data.lease_end),
      maintenance_hours: data.maintenance_hours / 3600,
      domain_lease_date: moment(data?.domain_lease_date),
      hosting_lease_date: moment(data?.hosting_lease_date),
      project: data?.project?.title,
      installments: mapInstallments(data?.installments),
    });

    setFormFields(editFields({ installmentsFields: data?.installments, form }));
  };

  const onSubmit = async (data) => {
    const maintenanceData = {
      ...data,
      project_id: location?.state?.project?.id,
    };

    await MaintenancesService.update(maintenanceId, maintenanceData);
    getData();
  };
  const mapInstallments = (installments) => {
    let installmentsMapped = {};
    installments.forEach((item) => {
      installmentsMapped[item.label] = item.value;
    });
    return installmentsMapped;
  };

  return (
    <div className='space-y-4'>
      <div className='flex flex-row items-center justify-between gap-x-8'>
        <div className='w-3/4'>
          <FormFactory
            form={form}
            onFinish={(data) => {
              onSubmit(data);
            }}
            fields={formFields}
          />
        </div>
        <div className='w-1/4 bg-zinc-50 self-start rounded-md mt-4 p-4 border-solid border border-slate-200 space-y-5'>
          <div className='text-lg font-medium'>Summary</div>
          <div className='sm:grid sm:grid-cols-3 sm:space-y-0 flex flex-col space-y-2'>
            <div className='col-span-1 space-y-1'>
              <div className='font-medium'>Total hours:</div>
              <div>
                {Utils.calculateTrackedTime(maintenanceData?.maintenance_hours)}
              </div>
            </div>
            <div className='col-span-1 space-y-1'>
              <div className='font-medium'>Hours used:</div>
              <div
                className={`${
                  maintenanceData?.maintenance_hours_used +
                    maintenanceData?.monitoring_hours_used >
                  maintenanceData?.maintenance_hours
                    ? 'text-yellow-400'
                    : ''
                }`}
              >
                {Utils.calculateTrackedTime(
                  maintenanceData?.maintenance_hours_used +
                    maintenanceData?.monitoring_hours_used
                )}
              </div>
            </div>
            <div className='col-span-1 space-y-1'>
              <div className='font-medium'>Hours left:</div>
              <div
                className={`${
                  maintenanceData?.maintenance_hours_left <=
                  maintenanceData?.maintenance_hours * 0.1
                    ? maintenanceData?.maintenance_hours_left < 0
                      ? 'text-red-600'
                      : 'text-orange-400'
                    : ''
                }`}
              >
                {maintenanceData?.maintenance_hours_used +
                  maintenanceData?.monitoring_hours_used >
                maintenanceData?.maintenance_hours
                  ? '-'
                  : ''}
                {Utils.calculateTrackedTime(
                  maintenanceData?.maintenance_hours_left
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className='sm:grid sm:grid-cols-3 sm:space-y-0 flex flex-col space-y-2'>
            <div className='col-span-1 space-y-1'>
              <div className='font-medium'>Hosting:</div>
              <div>
                {maintenanceData?.hosting_lease_date
                  ? moment(maintenanceData?.hosting_lease_date).format(
                      'DD.MM.YYYY'
                    )
                  : '-'}
              </div>
            </div>
            <div className='col-span-1 space-y-1'>
              <div className='font-medium'>Domain:</div>
              <div>
                {maintenanceData?.domain_lease_date
                  ? moment(maintenanceData?.domain_lease_date).format(
                      'DD.MM.YYYY'
                    )
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaintenancesTabs getMaintenanceData={getData} />
    </div>
  );
};

export default MaintenancesEdit;
