import { Checkbox, DatePicker, Input, Select, Switch, Tag } from 'antd';
import moment from 'moment';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import MainButton from 'src/components/buttons/MainButton';
import config from 'src/config/config';
import { MaintenancesPaymentStatusesData } from 'src/common/constants/maintenances';
import InstallmentCard from 'src/components/InstallmentCard';
import { MaintenancesPaymentStatuses } from 'src/common/enums/maintenances';
const { TextArea } = Input;
const { Option } = Select;

export const filterFields = ({ tags, users }) => {
  return [
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-2',
      options: {
        label: 'Start time',
        name: 'date_from',
        value: null,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-2',
      options: {
        label: 'End time',
        name: 'date_to',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          optionFilterProp='children'
          showSearch={true}
          placeholder='Select tag'
        >
          {tags.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-2',
      options: {
        label: 'Tag',
        name: 'tag_id',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Users'
          optionFilterProp='children'
          showSearch={true}
        >
          {users?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-2',
      options: {
        label: 'Users',
        name: 'user_id',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          optionFilterProp='children'
          placeholder='Status'
        >
          {MaintenancesPaymentStatusesData.map((option, index) => {
            return (
              <Option
                key={index}
                value={option.value}
              >
                {option.label}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-2',
      options: {
        label: 'Status',
        name: 'payment_status',
        value: null,
      },
    },
  ];
};

export const tableColumnsPostpaid = [
  {
    title: 'Project',
    dateIndex: ['project', 'title'],
    key: ['project', 'title'],
    render: (_, data) => <div>{data?.project?.title}</div>,
  },
  {
    title: 'Lease start',
    dataIndex: 'lease_start',
    key: 'lease_start',
    render: (_, data) => (
      <div>{moment(data?.lease_start).format(config.dateFormat)}</div>
    ),
  },
  {
    title: 'Lease end',
    dataIndex: 'lease_end',
    key: 'lease_end',
    render: (_, data) => (
      <div>{moment(data?.lease_end).format(config.dateFormat)}</div>
    ),
  },
  {
    title: 'Total hours',
    dataIndex: 'maintenance_hours',
    key: 'maintenance_hours',
    render: (_, data) => (
      <div>{Utils.calculateTrackedTime(data?.maintenance_hours)}</div>
    ),
  },
  {
    title: 'Hours left',
    dataIndex: 'maintenance_hours_left',
    key: 'maintenance_hours_left',
    render: (_, data) => (
      <div
        className={`${
          data?.maintenance_hours_left <= data?.maintenance_hours * 0.1
            ? data?.maintenance_hours_left < 0
              ? 'text-red-600'
              : 'text-orange-400'
            : ''
        }`}
      >
        {data?.maintenance_hours_left < 0 ? '-' : ''}
        {Utils.calculateTrackedTime(data?.maintenance_hours_left)}
      </div>
    ),
  },
  {
    title: 'Hours used',
    dataIndex: 'maintenance_hours_used',
    key: 'maintenance_hours_used',
    render: (_, data) => (
      <div>
        {Utils.calculateTrackedTime(
          data?.maintenance_hours_used + data?.monitoring_hours_used
        )}
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    key: 'is_active',
    render: (_, data) =>
      data.is_active ? (
        <Tag color='green'>Active</Tag>
      ) : (
        <Tag color='cyan'>Inactive</Tag>
      ),
  },
];
export const tableColumnsPrepaid = [
  {
    title: 'Project',
    dateIndex: ['project', 'title'],
    key: ['project', 'title'],
    render: (_, data) => <div>{data?.project?.title}</div>,
  },
  {
    title: 'Maintenance hours paid',
    dataIndex: 'maintenance_hours_paid',
    key: 'maintenance_hours_paid',
  },
  {
    title: 'Hourly rate',
    dataIndex: 'package_price',
    key: 'package_price',
  },
];

export const tableColumnsTimes = (updateTimeRecordStatus) => {
  return [
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      key: ['user', 'name'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Start time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_, data) => {
        return moment(data.start_time).format(config.dateTimeFormat);
      },
    },
    {
      title: 'Tag',
      dataIndex: ['tag', 'name'],
      key: ['tag', 'name'],
    },
    {
      title: 'Total time',
      dataIndex: 'total_time',
      key: 'total_time',
      render: (_, data) => {
        return Utils.calculateTrackedTime(data.total_time);
      },
    },
    {
      title: 'Payment status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (_, data) => {
        return (
          <Select
            optionFilterProp='children'
            onChange={(status) => {
              updateTimeRecordStatus({
                timeRecordId: data?.id,
                paymentStatus: status,
              });
            }}
            placeholder='Payment status'
            defaultValue={data?.payment_status}
            style={{ minWidth: 160 }}
            disabled={data?.payment_status === MaintenancesPaymentStatuses.PAID}
          >
            {MaintenancesPaymentStatusesData.map((option, index) => {
              return (
                <Option
                  key={index}
                  value={option.value}
                  disabled={option.value === MaintenancesPaymentStatuses.PAID}
                >
                  {option.label}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
  ];
};

export const tableColumnsForBilling = [
  {
    title: 'User',
    dataIndex: ['user', 'name'],
    key: ['user', 'name'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (_, data) => {
      return moment(data.start_time).format(config.dateTimeFormat);
    },
  },
  {
    title: 'Tag',
    dataIndex: ['tag', 'name'],
    key: ['tag', 'name'],
  },
  {
    title: 'Total time',
    dataIndex: 'total_time',
    key: 'total_time',
    render: (_, data) => {
      return Utils.calculateTrackedTime(data.total_time);
    },
  },
];

export const tableColumnsForPayment = [
  {
    title: 'Project',
    dataIndex: 'project',
    key: 'project',
  },
  {
    title: 'Date',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (_, data) => {
      return Utils.convertDateTime(data.date).slice(0, 10);
    },
  },
  {
    title: 'Total time',
    dataIndex: 'total_time',
    key: 'total_time',
    render: (_, data) => {
      return Utils.formatDecimalToTime(data.total_time);
    },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (_, data) => data?.price.toFixed(2),
  },
];

export const tableColumnsInstallments = [
  {
    title: 'Project',
    dataIndex: 'project',
    key: 'project',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Date',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (_, data) => {
      return moment(data.start_time).format(config.dateTimeFormat);
    },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (_, data) => data?.price?.toFixed(2),
  },
];

export const addFieldsPostpaid = ({ projectsDropdown }) => {
  return [
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Lease start',
        name: 'lease_start',
        value: null,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Lease end',
        name: 'lease_end',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          optionFilterProp='children'
          showSearch={true}
        >
          {projectsDropdown.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.title}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Project',
        name: 'project_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose project!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Package price',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Regular price',
        name: 'regular_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Installments number',
        name: 'installments_number',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6 lg:col-span-4',
      options: {
        label: 'Maintenance hours',
        name: 'maintenance_hours',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Monitoring hours monthly',
        name: 'monitoring_hours_monthly',
        value: null,
      },
    },
    {
      controlType: <Checkbox>Domain monitoring</Checkbox>,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: '',
        name: 'domain_is_leased',
        value: null,
        valuePropName: 'checked',
      },
    },
    {
      controlType: <Checkbox>Hosting monitoring</Checkbox>,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: '',
        name: 'hosting_is_leased',
        value: null,
        valuePropName: 'checked',
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Domain monitoring date',
        name: 'domain_lease_date',
        value: null,
        hide: true,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Hosting monitoring date',
        name: 'hosting_lease_date',
        value: null,
        hide: true,
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
    {
      colSize: 'col-span-6 ',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
  ];
};
export const addFieldsPrepaid = ({ projectsDropdown }) => [
  {
    controlType: <Input />,
    colSize: 'col-span-12',
    options: {
      label: 'Hourly rate',
      name: 'package_price',
      value: null,
    },
  },
  {
    controlType: <TextArea />,
    colSize: 'col-span-12',
    options: {
      label: 'Note',
      name: 'note',
      value: null,
    },
  },
  {
    controlType: (
      <Select
        optionFilterProp='children'
        showSearch={true}
      >
        {projectsDropdown.map((option) => {
          return (
            <Option
              key={option.id}
              value={option.id}
            >
              {option.title}
            </Option>
          );
        })}
      </Select>
    ),
    colSize: 'col-span-12',
    options: {
      label: 'Project',
      name: 'project_id',
      value: null,
      rules: [
        {
          required: true,
          message: 'Please choose project!',
        },
      ],
    },
  },
  {
    colSize: 'col-span-6 ',
    controlType: (
      <MainButton
        label='Submit'
        type='submit'
        className='mt-2 mx-auto w-fit'
        icon={ICONS.save}
      />
    ),
  },
];

export const editPrepaidFields = () => {
  return [
    {
      colSize: 'col-span-6 ',
      controlType: <h2 className='font-normal '>Maintenance - Edit</h2>,
    },
    {
      colSize: 'col-span-6 flex items-center justify-end',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-3',
      options: {
        label: 'Hourly rate',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
  ];
};
export const editFields = ({ installmentsFields, form }) => {
  const fields = [];
  installmentsFields.forEach((installment) => {
    fields.push(createInstallmentFiled(installment, form));
  });

  return [
    {
      colSize: 'col-span-6',
      controlType: <h2 className='font-normal '>Maintenance - Edit</h2>,
    },
    {
      colSize: 'col-span-6 flex items-center justify-end',
      controlType: (
        <MainButton
          label='Submit'
          type='submit'
          className='mt-2 mx-auto w-fit'
          icon={ICONS.save}
        />
      ),
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          disabled={true}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Lease start',
        name: 'lease_start',
        value: null,
      },
    },
    {
      controlType: (
        <DatePicker
          className='w-full'
          format={'DD.MM.YYYY'}
          disabled={true}
          clearIcon={true}
        />
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Lease end',
        name: 'lease_end',
        value: null,
      },
    },
    {
      controlType: <Input disabled={true} />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Project',
        name: 'project',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Package price',
        name: 'package_price',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Regular price',
        name: 'regular_price',
        value: null,
      },
    },
    {
      controlType: <Input disabled={true} />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Installments number',
        name: 'installments_number',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Monitoring hours monthly',
        name: 'monitoring_hours_monthly',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Total hours',
        name: 'maintenance_hours',
        value: null,
      },
    },
    {
      controlType: <TextArea />,
      colSize: 'col-span-12',
      options: {
        label: 'Note',
        name: 'note',
        value: null,
      },
    },
    {
      colSize: 'col-span-12',
      controlType: <h2 className='font-normal mb-0'>Installments</h2>,
    },
    ...fields,
  ];
};

export const createInstallmentFiled = (installment, form) => {
  return {
    controlType: (
      <InstallmentCard
        installment={installment}
        form={form}
      />
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      name: ['installments', installment.label],
      valuePropName: 'checked',
    },
  };
};

export const createMonitoringField = ({ label, name }) => {
  return {
    controlType: (
      <DatePicker
        className='w-full'
        format={'DD.MM.YYYY'}
        disabled={true}
        clearIcon={true}
      />
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: label,
      name: name,
      value: null,
    },
  };
};

export const tableColumnsAllTasks = [];
