import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { openModal } from 'src/common/actions/modal';
import MaintenancesService from 'src/common/api/MaintenancesService';
import { ICONS } from 'src/common/Icons';
import MainButton from 'src/components/buttons/MainButton';
import Table from 'src/components/Table';
import { tableColumnsPostpaid } from './maintenances-fields';
import MaintenancesAdd from './MaintenancesAdd';

const MaintenancesList = ({ clientId }) => {
  const [prepaidData, setPrepaidData] = useState(null);
  const [postpaidData, setPostpaidData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getPrepaidData();
    getPostpaidData();
  }, []);

  const getPostpaidData = async (page = 1) => {
    let response = await MaintenancesService.all({ page, clientId });
    setPostpaidData(null);
    setPostpaidData(response);
  };

  const getPrepaidData = async (page = 1) => {
    let response = await MaintenancesService.all({
      page,
      clientId,
      is_postpaid: 1,
    });

    setPrepaidData(null);
    setPrepaidData(response);
  };

  const onCreate = ({ postpaid }) => {
    dispatch(
      openModal({
        title: 'Create maintenance',
        component: (
          <MaintenancesAdd
            onSuccess={postpaid ? getPostpaidData : getPrepaidData}
            clientId={clientId}
            postpaid={postpaid}
          />
        ),
      })
    );
  };

  const onDeletePostpaid = ({ id }) => {
    MaintenancesService.delete(id).then(() => {
      getPostpaidData();
    });
  };

  const onDeletePrepaid = ({ id }) => {
    MaintenancesService.delete(id).then(() => {
      getPrepaidData();
    });
  };

  return (
    <div className='mt-5'>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>Maintenance packages</h2>
        <MainButton
          label='Create'
          onClick={() =>
            onCreate({
              postpaid: true,
            })
          }
          icon={ICONS.add}
        />
      </div>
      {postpaidData && (
        <Table
          data={postpaidData}
          columns={tableColumnsPostpaid}
          onFilter={(page) => getPostpaidData(page)}
          onDelete={(data) => onDeletePostpaid(data)}
          onEdit={(data) =>
            navigate(`postpaid/${data.id}`, {
              state: { project: data?.project },
            })
          }
        />
      )}
    </div>
  );
};

export default MaintenancesList;
