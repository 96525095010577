import {
  DeleteOutlined,
  EditOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  SearchOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  FundProjectionScreenOutlined,
  TagOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  CaretRightOutlined,
  MinusSquareFilled,
  RedoOutlined,
  ExportOutlined,
  LinkOutlined,
} from '@ant-design/icons';

export const ICONS = {
  link: <LinkOutlined />,
  save: <SaveOutlined />,
  search: <SearchOutlined />,
  export: <ExportOutlined />,
  reset: <RedoOutlined />,
  login: <LoginOutlined />,
  add: <PlusCircleOutlined />,
  edit: <EditOutlined />,
  delete: <DeleteOutlined />,
  dashboard: <DashboardOutlined />,
  timer: <FieldTimeOutlined />,
  userSwitch: <UserSwitchOutlined />,
  projects: <FundProjectionScreenOutlined />,
  tag: <TagOutlined />,
  userAdd: <UserAddOutlined />,
  userGroupAdd: <UsergroupAddOutlined />,
  start: <CaretRightOutlined />,
  stop: <MinusSquareFilled className='bg-[#1f0000] group-hover:bg-white border-none text-xs transition-none' />,
  closeCircle: <CloseCircleOutlined />,
  more: <MoreOutlined />,
};
