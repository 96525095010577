import { Input, Select, DatePicker } from 'antd';
import moment from 'moment';
import { colorOptions } from 'src/common/Static';
import Utils from 'src/common/Utils';
import config from 'src/config/config';
const { Option } = Select;
const { TextArea } = Input;

export const tableColumns = (isAdmin) => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'id',
  },
  {
    title: 'Client',
    dataIndex: ['client', 'name'],
    key: ['client', 'name'],
  },
  {
    title: 'Maintenance',
    dataIndex: 'maintenance_hours',
    key: 'maintenance_hours',
    render: (_, data) =>
      !isAdmin
        ? '-'
        : Utils.formatDecimalToTime(data?.maintenance_hours) ?? '-',
  },
  {
    title: 'Hours used',
    dataIndex: 'maintenance_used_hours',
    key: 'maintenance_used_hours',
    sortDirections: ['descend', 'ascend'],
    sorter: (e) => {},
    render: (_, data) =>
      !isAdmin
        ? '-'
        : Utils.formatDecimalToTime(data?.maintenance_used_hours) ?? '-',
  },
  {
    title: 'Lease end',
    dataIndex: 'maintenance_lease_end',
    key: 'maintenance_lease_end',
    render: (_, data) =>
      !isAdmin
        ? '-'
        : data?.maintenance_lease_end
        ? moment(data?.maintenance_lease_end).format(config.dateFormat)
        : '-',
  },
];

export const filterFields = [
  {
    controlType: <Input allowClear />,
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Search',
      name: 'search',
      value: null,
    },
  },
  {
    controlType: (
      <DatePicker
        className='w-full'
        format={'DD.MM.YYYY'}
        clearIcon={true}
      />
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Start time',
      name: 'date_from',
      value: null,
    },
  },
  {
    controlType: (
      <DatePicker
        className='w-full'
        format={'DD.MM.YYYY'}
        clearIcon={true}
      />
    ),
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'End time',
      name: 'date_to',
      value: null,
    },
  },
];

export const mainFields = ({ clientsDropdown, headerTitle, isAdmin }) => {
  return [
    ...Utils.createHeader({ title: headerTitle, hideSave: !isAdmin }),
    {
      controlType: <Input disabled={!isAdmin} />,
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Title',
        name: 'title',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input project title!',
          },
        ],
      },
    },
    {
      controlType: (
        <Select
          disabled={!isAdmin}
          optionFilterProp='children'
          showSearch={true}
        >
          {clientsDropdown.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Client',
        name: 'client_id',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please choose client!',
          },
        ],
      },
    },
    {
      controlType: (
        <Select disabled={!isAdmin}>
          {colorOptions.map((option) => {
            return (
              <Option
                key={option.value}
                value={option.value}
              >
                <div
                  style={{ background: option.value }}
                  className='rounded-md px-2 h-2 mt-[10px] text-white'
                ></div>
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-12 sm:col-span-4',
      options: {
        label: 'Color',
        name: 'color',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input tag color!',
          },
        ],
      },
    },
    {
      controlType: (
        <TextArea
          rows={3}
          disabled={!isAdmin}
        />
      ),
      colSize: 'col-span-12',
      options: {
        label: 'Description',
        name: 'description',
        value: null,
      },
    },
  ];
};
