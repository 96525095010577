import axiosInstance from 'axios';
import config from 'src/config/config';
import Toast from 'src/common/Toast';
import { store } from 'src';
import { autoLogoutUser } from 'src/common/actions/user';

// Creating axios instance for routes that are api protected
export const axios = axiosInstance.create({
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

/*
    Add a request interceptor to add authentication token on each request
*/
axios.interceptors.request.use(
  async function (config) {
    const reduxStore = store.getState();

    if (reduxStore.auth.user) {
      config.headers.Authorization = `Bearer ${reduxStore.auth.user.token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/*
    Add a response interceptor to handle errors
*/
axios.interceptors.response.use(
  (response) => {
    response = response.data;
    if (response.message) {
      Toast.success(response.message);
    }

    return response;
  },
  async (error) => {
    error = error?.response;

    switch (error?.status) {
      case 401:
        if (isUserAuthenticated()) {
          Toast.error('You are not authorized for this action.');
        }
        autoLogoutUser();
        break;
      case 403:
        Toast.error(
          error.data.message || 'Access to this action is forbidden.'
        );
        // await logoutUser();
        break;
      case 404:
        Toast.error('Requested resource not found. Please try again.');
        break;
      case 419:
        Toast.error('Session expired. Please login again.');
        // await logoutUser();
        break;
      case 422:
        Toast.error(error.data.message || 'This action access is forbidden.');
        break;
      case 429:
        Toast.warning('Too many requests. Try again later.');
        break;
      case 503:
        Toast.warning('Work under construction. Please wait a moment.');
        break;
      default:
        Toast.error('Unexpected error occurred. Please try again alter.');
    }

    return Promise.reject(error);
  }
);

const isUserAuthenticated = () => {
  const reduxStore = store.getState();
  if (reduxStore.auth.user) {
    return true;
  }
  return false;
};
