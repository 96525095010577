import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import FormFactory from 'src/components/FormFactory';
import { mainFields } from './kpi-fields';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import KpiService from 'src/common/api/KpiService';
import Utils from 'src/common/Utils';
import { closeModal } from 'src/common/actions/modal';

const KpiAdd = ({ templateData, user, quarter }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const shouldCreateNew = location?.state?.mode === 'new';

  useEffect(() => {
    form.setFieldsValue({
      ...location?.state?.item,
      quarter: quarter ? quarter : Utils.getCurrentQuarter(),
      year: new Date().getFullYear().toString(),
    });
  }, []);

  const onSubmit = async (data) => {
    if (shouldCreateNew) {
      await KpiService.create({
        user_id: location?.state?.user?.id,
        kpi_template: {
          title: data?.title,
          description: data?.description,
          kpi_type: data?.kpi_type,
          expected_grade: data?.expected_grade,
        },
        year: data?.year,
        quarter: data?.quarter,
        grade: null,
        note: Utils.getQuarterMonths(data?.quarter),
      });
      navigate(`/kpi/users/${location?.state?.user?.id}`, {
        state: {
          quarter: data?.quarter,
        },
      });
    } else {
      await KpiService.create({
        user_id: user?.id,
        kpi_template_id: templateData?.id,
        year: data?.year,
        quarter: data?.quarter,
        grade: null,
        note: Utils.getQuarterMonths(data?.quarter),
      });

      dispatch(closeModal());
      navigate(`/kpi/users/${user?.id}`, {
        state: {
          quarter: data?.quarter,
        },
      });
    }
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={mainFields('KPI - Create')}
      />
    </div>
  );
};

export default KpiAdd;
