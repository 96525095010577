import React from 'react';
import { Tooltip, Switch } from 'antd';

const InstallmentCard = ({ installment, form }) => {
  return (
    <div className='bg-zinc-50 self-start rounded-md mt-4 p-4 border-solid border border-slate-200 space-y-5'>
      <div className='flex md:flex-row sm:justify-between flex-col'>
        <div className='text-lg font-medium'>{installment?.description}</div>
        <Tooltip title='Is paid?'>
          <Switch
            defaultChecked={installment?.value}
            onChange={(value) => {
              form.setFieldValue(['installments', installment?.label], value);
            }}
            className='w-fit'
          />
        </Tooltip>
      </div>
      <div className='md:grid md:grid-cols-2 md:space-y-0 flex flex-col space-y-2'>
        <div className='col-span-1 space-y-1'>
          <div className='font-medium'>Date from:</div>
          <div>{installment?.start_date}</div>
        </div>
        <div className='col-span-1 space-y-1'>
          <div className='font-medium'>Date to:</div>
          <div>{installment?.end_date}</div>
        </div>
      </div>
    </div>
  );
};

export default InstallmentCard;
