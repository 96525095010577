import { Select } from 'antd';

const { Option } = Select;

export const mainFields = ({ users, projects, clients, tags }) => {
  return [
    {
      controlType: (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Users'
          optionFilterProp='children'
          showSearch={true}
        >
          {users?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        name: 'user_id',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Projects'
          optionFilterProp='children'
          showSearch={true}
        >
          {projects?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.title}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        name: 'project_id',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Clients'
          optionFilterProp='children'
          showSearch={true}
        >
          {clients?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        name: 'client_id',
        value: null,
      },
    },
    {
      controlType: (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          placeholder='Tags'
          optionFilterProp='children'
          showSearch={true}
        >
          {tags?.map((option) => {
            return (
              <Option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </Option>
            );
          })}
        </Select>
      ),
      colSize: 'col-span-6 sm:col-span-3',
      options: {
        name: 'tag_id',
        value: null,
      },
    },
  ];
};
