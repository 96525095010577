import { MaintenancesPaymentStatuses } from '../enums/maintenances';

export const MaintenancesPaymentStatusesData = [
  {
    label: 'No status',
    value: MaintenancesPaymentStatuses?.EMPTY,
  },
  // {
  //   label: 'Package included',
  //   value: MaintenancesPaymentStatuses?.PACKAGE_INCLUDED,
  // },
  {
    label: 'Free of charge',
    value: MaintenancesPaymentStatuses?.FREE_OFF_CHARGE,
  },
  {
    label: 'For billing',
    value: MaintenancesPaymentStatuses?.FOR_BILLING,
  },
  {
    label: 'Paid',
    value: MaintenancesPaymentStatuses?.PAID,
  },
];
