import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router';
import MaintenanceAllTasks from './MaintenancesAllTasks';
import MaintenancesService from 'src/common/api/MaintenancesService';
import MaintenancesForBilling from './MaintenancesForBilling';
import MaintenancesForPayment from './MaintenancesForPayment';

const MaintenancesTabs = ({ getMaintenanceData }) => {
  const [allTasks, setAllTasks] = useState(null);
  const [forBilling, setForBilling] = useState(null);
  const [forPayment, setForPayment] = useState(null);

  const { maintenanceId } = useParams();

  const onTabChange = (tab) => {
    switch (tab) {
      case '1':
        getAllTasks(1);
        break;
      case '2':
        getForBilling();
        break;
      case '3':
        getForPayment();
    }
  };

  const getAllTasks = async (page = 1, query) => {
    let response = await MaintenancesService.allTimeRecords({
      page,
      maintenanceId,
      query,
    });
    setAllTasks(response);
  };

  const getForBilling = async () => {
    let response =
      await MaintenancesService.allForBillingTimeRecords(maintenanceId);

    setForBilling(response?.data);
  };

  const getForPayment = async () => {
    let response =
      await MaintenancesService.allForPaymentTimeRecords(maintenanceId);

    setForPayment(response?.data);
  };

  return (
    <Tabs
      defaultActiveKey='1'
      items={[
        {
          key: '1',
          label: 'All tasks',
          children: (
            <MaintenanceAllTasks
              allTasks={allTasks}
              getAllTasks={getAllTasks}
              getMaintenanceData={getMaintenanceData}
            />
          ),
        },
        {
          key: '2',
          label: 'For billing',
          children: (
            <MaintenancesForBilling
              forBilling={forBilling}
              getForBilling={getForBilling}
            />
          ),
        },
        {
          key: '3',
          label: 'Ready for payment',
          children: (
            <MaintenancesForPayment
              forPayment={forPayment}
              getForPayment={getForPayment}
            />
          ),
        },
      ]}
      onChange={onTabChange}
    />
  );
};

export default MaintenancesTabs;
