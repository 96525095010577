import React from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import Table from 'src/components/Table';
import {
  tableColumnsForPayment,
  tableColumnsInstallments,
} from '../maintenances-fields';
import { useParams } from 'react-router';
import { Switch, Popconfirm, Tooltip } from 'antd';

const MaintenancesForPayment = ({ forPayment, getForPayment }) => {
  const { maintenanceId } = useParams();

  const onPaidPackageToggle = async (packageId) => {
    await MaintenancesService.togglePackage(packageId);
    getForPayment();
  };

  // const onInstallmentToggle = async ({
  //   installmentName,
  //   installmentIsPaid,
  //   packagePrice,
  // }) => {
  //   const maintenanceData = {
  //     // package_price: packagePrice,
  //     project_id: forPayment?.project?.id,
  //     installments: {
  //       [installmentName]: installmentIsPaid,
  //     },
  //   };

  //   MaintenancesService.update(maintenanceId, maintenanceData);
  //   getForPayment();
  // };

  return (
    <div className='flex flex-col mt-5 space-y-4'>
      <h3 className='font-normal'>Ready for payment</h3>
      <Table
        data={{
          data: forPayment?.read_for_billing,
        }}
        columns={[
          ...tableColumnsForPayment,
          {
            title: 'Paid',
            dataIndex: 'is_paid',
            key: 'is_paid',
            render: (_, data) => {
              return (
                <Popconfirm
                  title='Are you sure you want to toggle maintenance as paid?'
                  onConfirm={() => onPaidPackageToggle(data?.id)}
                  okText='Yes'
                  cancelText='No'
                  style={{
                    color: 'red',
                  }}
                  disabled={data?.is_paid}
                >
                  <Tooltip title='Paid'>
                    <Switch
                      {...(data?.is_paid ? { defaultChecked: true } : {})}
                      checked={data?.is_paid}
                      onChange={() => {}}
                      disabled={data?.id_paid}
                    />
                  </Tooltip>
                </Popconfirm>
              );
            },
          },
        ]}
        onFilter={() => getForPayment()}
        hideActions={true}
      />

      {/* <h3 className='font-normal'>Installments</h3>
      <Table
        data={{
          data: forPayment?.installments,
        }}
        columns={[
          ...tableColumnsInstallments,
          {
            title: 'Paid',
            dataIndex: 'is_paid',
            key: 'is_paid',
            render: (_, data) => {
              return (
                <Popconfirm
                  title='Are you sure you want to toggle installment as paid?'
                  onConfirm={() =>
                    onInstallmentToggle({
                      installmentName: data?.period,
                      installmentIsPaid: data?.is_paid,
                      packagePrice: data?.price,
                    })
                  }
                  okText='Yes'
                  cancelText='No'
                  style={{
                    color: 'red',
                  }}
                >
                  <Tooltip title='Paid'>
                    <Switch
                      {...(data?.is_paid ? { defaultChecked: true } : {})}
                      checked={data?.is_paid}
                      onChange={() => {}}
                    />
                  </Tooltip>
                </Popconfirm>
              );
            },
          },
        ]}
        onFilter={() => getForPayment()}
        hideActions={true}
      /> */}
    </div>
  );
};

export default MaintenancesForPayment;
