import { Button } from 'antd';
import React from 'react';

const MainButton = ({ label, type, hide, className, ...rest }) => {
  if (hide) {
    return <></>;
  }

  return (
    <Button
      {...rest}
      type='primary'
      htmlType={type}
      className={
        'flex items-center justify-center w-fit ml-0 bg-[#132038] hover:!bg-[#294578]' +
        className
      }
    >
      {label}
    </Button>
  );
};

export default MainButton;
