import { useEffect, useState } from 'react';
import { filterFields, tableColumns } from './kpi-templates-fields';
import Table from 'src/components/Table';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { openModal } from 'src/common/actions/modal';
import KpiTemplatesService from 'src/common/api/KpiTemplatesService';
import MainButton from 'src/components/buttons/MainButton';
import { ICONS } from 'src/common/Icons';
import KpiAdd from '../KpiAdd';

const KpiTemplatesList = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createFromTemplate = location?.state?.mode === 'template';
  const user = location?.state?.user;
  const quarter = location?.state?.quarter;

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = '') => {
    const response = await KpiTemplatesService.all({ query });
    setData(response);
  };

  const onDelete = async ({ id }) => {
    await KpiTemplatesService.delete(id);
    getData();
  };

  const openEditModal = (templateData) => {
    dispatch(
      openModal({
        title: 'Choose a quarter and year for the selected KPI',
        component: (
          <KpiAdd
            templateData={templateData}
            user={user}
            quarter={quarter}
          />
        ),
      })
    );
  };

  return (
    <div>
      <div className='flex justify-between items-center flex-wrap mb-2'>
        <h2 className='font-normal'>KPIs Templates</h2>
        {!createFromTemplate && (
          <MainButton
            className='bg-primary'
            label='Create'
            onClick={() => navigate('/kpi/templates/create')}
            icon={ICONS.add}
          />
        )}
      </div>

      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields()}
        onFilter={(query) => {
          getData(query);
        }}
        onDelete={(data) => onDelete(data)}
        hideActions={createFromTemplate}
        onRowClick={
          createFromTemplate
            ? (item) =>
                navigate('/kpi/create', {
                  state: {
                    item,
                    user,
                    mode: 'new',
                  },
                })
            : null
        }
      />
    </div>
  );
};

export default KpiTemplatesList;
