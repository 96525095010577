import { Input } from 'antd';
import moment from 'moment';
import Utils from 'src/common/Utils';
import config from 'src/config/config';

export const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'id',
  },
  {
    title: 'Contact person',
    dataIndex: 'contact_person',
    key: 'contact_person',
  },
  {
    title: 'Total hours',
    dataIndex: 'maintenance_hours',
    key: 'maintenance_hours',
    sortDirections: ['descend', 'ascend'],
    sorter: (e) => {},
    render: (_, data) => (
      <div>{Utils.calculateTrackedTime(data?.maintenance_hours)}</div>
    ),
  },
  {
    title: 'Hours used',
    dataIndex: 'maintenance_used_hours',
    key: 'maintenance_used_hours',
    sortDirections: ['descend', 'ascend'],
    sorter: (e) => {},
    render: (_, data) => (
      <div>{Utils.calculateTrackedTime(data?.maintenance_used_hours)}</div>
    ),
  },
  {
    title: 'Hours left',
    dataIndex: 'maintenance_left_hours',
    key: 'maintenance_left_hours',
    sortDirections: ['descend', 'ascend'],
    sorter: (e) => {},
    render: (_, data) => (
      <div
        className={`${
          data?.maintenance_left_hours <= data?.maintenance_hours * 0.1 &&
          data?.maintenance_left_hours !== 0
            ? data?.maintenance_left_hours < 0
              ? 'text-red-600'
              : 'text-orange-400'
            : ''
        }`}
      >
        {data?.maintenance_left_hours < 0 ? '-' : ''}
        {Utils.calculateTrackedTime(data?.maintenance_left_hours)}
      </div>
    ),
  },
  {
    title: 'Lease end',
    dataIndex: 'maintenance_lease_end',
    key: 'maintenance_lease_end',
    sortDirections: ['descend', 'ascend'],
    sorter: (e) => {},
    render: (_, data) => (
      <div>
        {data?.maintenance_lease_end
          ? moment(data?.maintenance_lease_end).format(config.dateFormat)
          : '-'}
      </div>
    ),
  },
];

export const filterFields = [
  {
    controlType: <Input allowClear />,
    colSize: 'col-span-12 sm:col-span-3',
    options: {
      label: 'Search',
      name: 'search',
      value: null,
    },
  },
];

export const mainFields = ({ headerTitle }) => {
  return [
    ...Utils.createHeader({ title: headerTitle }),
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Company / Client name',
        name: 'name',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input client name!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Contact person',
        name: 'contact_person',
        value: null,
        rules: [
          {
            required: true,
            message: 'Please input contact person name!',
          },
        ],
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Phone',
        name: 'phone',
        value: null,
      },
    },
    {
      controlType: <Input />,
      colSize: 'col-span-12 sm:col-span-6',
      options: {
        label: 'Email',
        name: 'email',
        value: null,
      },
    },
  ];
};
