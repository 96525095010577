import React, { useState } from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import Table from 'src/components/Table';
import { tableColumnsForBilling } from '../maintenances-fields';
import MainButton from 'src/components/buttons/MainButton';
import { ICONS } from 'src/common/Icons';
import Utils from 'src/common/Utils';
import { useParams } from 'react-router';

const MaintenancesForBilling = ({ forBilling, getForBilling }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { maintenanceId } = useParams();

  const onExportTimeRecords = async () => {
    let response = await MaintenancesService.exportMaintenanceTimeRecord(
      maintenanceId,
      'payment_status=3'
    );
    window.open(response?.data, '_blank', 'noopener,noreferrer');
  };

  const onReadyForPayment = async () => {
    await MaintenancesService.toggleReadyForPayment({
      maintenance_id: maintenanceId,
      time_records: selectedRows,
    });

    getForBilling();
  };

  return (
    <div className='flex flex-col mt-5 space-y-4'>
      <div className='flex flex-row justify-between mb-2 items-center'>
        <h3 className='font-normal'>For billing</h3>
        <div className='flex flex-row items-center gap-4'>
          <h3 className='font-normal'>
            TOTAL: {Utils.calculateTrackedTime(forBilling?.total_time)}
          </h3>
          <MainButton
            label='Export'
            type='button'
            className='w-fit'
            onClick={onExportTimeRecords}
            icon={ICONS.export}
          />
        </div>
      </div>
      <Table
        setSelectedRows={setSelectedRows}
        data={{
          data: forBilling?.time_records,
        }}
        columns={tableColumnsForBilling}
        onFilter={() => getForBilling()}
        hideActions={true}
      />
      <MainButton
        label='Make ready for payment'
        type='button'
        className='w-fit self-end'
        onClick={onReadyForPayment}
        icon={ICONS.add}
      />
    </div>
  );
};

export default MaintenancesForBilling;
