import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import MaintenancesService from 'src/common/api/MaintenancesService';
import FormFactory from 'src/components/FormFactory';
import { closeModal } from 'src/common/actions/modal';
import { useDispatch } from 'react-redux';
import { addFieldsPostpaid } from './maintenances-fields';
import ProjectsService from 'src/common/api/ProjectsService';

const MaintenancesAdd = ({ onSuccess, clientId, postpaid }) => {
  const [form] = Form.useForm();
  const domainToggle = Form.useWatch('domain_is_leased', form);
  const hostingToggle = Form.useWatch('hosting_is_leased', form);
  const [formFields, setFormFields] = useState([]);
  const dispatch = useDispatch();

  const getProjects = async () => {
    let response = await ProjectsService.allWithoutPagination();
    setFormFields(
      addFieldsPostpaid({
        projectsDropdown: response?.data,
      })
    );
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    setFormFields((fields) =>
      fields.map((field) => {
        if (field?.options?.name === 'domain_lease_date') {
          return {
            ...field,
            options: { ...field.options, hide: !domainToggle ?? true },
          };
        }
        if (field?.options?.name === 'hosting_lease_date') {
          return {
            ...field,
            options: { ...field.options, hide: !hostingToggle ?? true },
          };
        }
        return field;
      })
    );
  }, [domainToggle, hostingToggle]);

  const onSubmit = (data) => {
    let prePaidData = { ...data, lease_start: null, lease_end: null };
    MaintenancesService.create(postpaid ? data : prePaidData, clientId).then(
      (res) => {
        if (!res.status) {
          onSuccess();
          dispatch(closeModal());
          form.resetFields();
        }
      }
    );
  };

  return (
    <div>
      <FormFactory
        form={form}
        onFinish={(data) => {
          onSubmit(data);
        }}
        fields={formFields}
      />
    </div>
  );
};

export default MaintenancesAdd;
